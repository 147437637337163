
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
} from "vue";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import { ErrorMessage, Field, useForm } from "vee-validate";
import { AddressInterface } from "@/modules/addresses/interfaces/Address.interface";
import ApiService from "@/core/services/ApiService";
import { ElMessage } from "element-plus/es";
import Notify from "@/modules/common/helpers/Notify";
import SymbolCounter from "@/modules/pathfinder/widgets/SymbolCounter.vue";

interface ComponentData {
  submitButtonLoading: boolean;
  updatedAddress?: AddressInterface;
}

export default defineComponent({
  name: "PathfinderNameFixModal",

  components: {
    ModalComponent,
    FormSubmitButton,
    Field,
    ErrorMessage,
    SymbolCounter,
  },
  props: {
    reference: {
      type: String,
      required: true,
    },
    address: {
      type: Object as PropType<AddressInterface>,
      required: true,
    },
    messages: {
      type: Array,
      required: true,
    },
  },

  emits: ["updated", "hide"],

  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      submitButtonLoading: false,
      updatedAddress: undefined,
    });
    const modal = ref<typeof ModalComponent | null>(null);

    const { setValues, handleSubmit, setFieldError } = useForm();

    const updateAddress = handleSubmit((values) => {
      ApiService.put(
        "pathfinder/address-name-update/" + props.address.id.toString(),
        values
      )
        .then(() => {
          state.submitButtonLoading = false;
          ElMessage.success("Address name updated.");
          modal.value?.hide();
          emit("updated");
        })
        .catch((error) => {
          Notify.apiError(error, setFieldError);
        })
        .finally(() => {
          state.submitButtonLoading = false;
        });
    });

    onMounted(() => {
      state.updatedAddress = JSON.parse(JSON.stringify(props.address));
    });

    const firstnameLength = computed(() => {
      return state.updatedAddress?.firstname.length;
    });

    const lastnameLength = computed(() => {
      return state.updatedAddress?.lastname?.length;
    });

    return {
      state,
      updateAddress,
      modal,
      firstnameLength,
      lastnameLength,
    };
  },
});
