<template>
  <modal-component
    id="pathfinder-fix-address-name-modal"
    ref="modal"
    size="large"
    @hide="$emit('hide')"
  >
    <template #title>Pathfinder - Fix address {{ address.id }} </template>
    <template #default>
      <div v-for="message in messages" :key="message.id" class="row mb-1">
        <strong class="text-danger">{{ message }}</strong>
      </div>

      <form
        v-if="state.updatedAddress"
        autocomplete="off"
        class="mt-4"
        @submit="updateAddress"
      >
        <div class="row mb-3">
          <div class="col-2 m-auto">
            <label class="form-label"> Reference </label>
          </div>
          <div class="col-10">
            <input
              class="form-control form-control-solid"
              :value="reference"
              disabled
            />
          </div>
        </div>

        <div class="row">
          <div class="col-1 m-auto">
            <label class="form-label"> Prev Name </label>
          </div>
          <div class="col-5">
            <input
              class="form-control form-control-solid"
              :value="address.firstname"
              disabled
            />
          </div>

          <div class="col-1 m-auto">
            <label class="form-label"> Prev Surname </label>
          </div>
          <div class="col-5">
            <input
              class="form-control form-control-solid"
              :value="address.lastname"
              disabled
            />
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-1 m-auto">
            <label for="firstname" class="form-label"> Name </label>
          </div>
          <div class="col-5">
            <symbol-counter :current-number="firstnameLength" :min-length="3">
              <Field
                id="firstname"
                v-model="state.updatedAddress.firstname"
                type="text"
                class="form-control form-control-solid"
                name="firstname"
                placeholder="Firstname"
                required
                autocomplete="nope"
              />
            </symbol-counter>
          </div>

          <div class="col-1 m-auto">
            <label for="lastname" class="form-label"> Surname </label>
          </div>
          <div class="col-5">
            <symbol-counter :current-number="lastnameLength" :min-length="3">
              <Field
                id="lastname"
                v-model="state.updatedAddress.lastname"
                type="text"
                class="form-control form-control-solid"
                name="lastname"
                placeholder="Lastname"
                required
                autocomplete="nope"
              />
            </symbol-counter>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-6">
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="firstname" />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="lastname" />
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-5">
          <form-submit-button :loading="state.submitButtonLoading">
            Submit & Verify
          </form-submit-button>
        </div>
      </form>
    </template>
  </modal-component>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
} from "vue";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import { ErrorMessage, Field, useForm } from "vee-validate";
import { AddressInterface } from "@/modules/addresses/interfaces/Address.interface";
import ApiService from "@/core/services/ApiService";
import { ElMessage } from "element-plus/es";
import Notify from "@/modules/common/helpers/Notify";
import SymbolCounter from "@/modules/pathfinder/widgets/SymbolCounter.vue";

interface ComponentData {
  submitButtonLoading: boolean;
  updatedAddress?: AddressInterface;
}

export default defineComponent({
  name: "PathfinderNameFixModal",

  components: {
    ModalComponent,
    FormSubmitButton,
    Field,
    ErrorMessage,
    SymbolCounter,
  },
  props: {
    reference: {
      type: String,
      required: true,
    },
    address: {
      type: Object as PropType<AddressInterface>,
      required: true,
    },
    messages: {
      type: Array,
      required: true,
    },
  },

  emits: ["updated", "hide"],

  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      submitButtonLoading: false,
      updatedAddress: undefined,
    });
    const modal = ref<typeof ModalComponent | null>(null);

    const { setValues, handleSubmit, setFieldError } = useForm();

    const updateAddress = handleSubmit((values) => {
      ApiService.put(
        "pathfinder/address-name-update/" + props.address.id.toString(),
        values
      )
        .then(() => {
          state.submitButtonLoading = false;
          ElMessage.success("Address name updated.");
          modal.value?.hide();
          emit("updated");
        })
        .catch((error) => {
          Notify.apiError(error, setFieldError);
        })
        .finally(() => {
          state.submitButtonLoading = false;
        });
    });

    onMounted(() => {
      state.updatedAddress = JSON.parse(JSON.stringify(props.address));
    });

    const firstnameLength = computed(() => {
      return state.updatedAddress?.firstname.length;
    });

    const lastnameLength = computed(() => {
      return state.updatedAddress?.lastname?.length;
    });

    return {
      state,
      updateAddress,
      modal,
      firstnameLength,
      lastnameLength,
    };
  },
});
</script>

<style scoped>
label {
  margin-bottom: 0;
}
.form-control:disabled {
  background: none;
  border: none;
}
</style>
