<template>
  <div class="position-relative">
    <slot />

    <span
      class="
        position-absolute
        top-0
        start-100
        translate-middle
        badge
        rounded-pill
      "
      :class="{
        'bg-primary': currentNumber >= minLength,
        'bg-danger': currentNumber < minLength,
      }"
    >
      {{ currentNumber }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "SymbolCounter",
  props: {
    minLength: {
      type: Number as PropType<number>,
      required: false,
      default: 0,
    },
    currentNumber: {
      type: Number as PropType<number>,
      required: true,
    },
  },
});
</script>

<style scoped></style>
