import { MultiselectOptions } from "@/components/multiselect-options/MultiselectOptionsInterface";

export const setOptionsValues = (items) => {
  // Function prepares options values for Multiselect widget
  const options: MultiselectOptions[] = [];

  for (const item of items) {
    options.push({
      value: item.id,
      name: item.name,
      label: item.name,
    });
  }
  return options;
};
