<template>
  <modal-component
    id="pathfinder-fix-address-modal"
    ref="modal"
    size="large"
    @hide="$emit('hide')"
  >
    <template #title>Pathfinder - Fix address {{ address.id }} </template>
    <template #default>
      <div v-for="message in messages" :key="message.id" class="row mb-1">
        <strong class="text-danger">{{ message }}</strong>
      </div>

      <form
        v-if="state.updatedAddress"
        autocomplete="off"
        class="mt-4"
        @submit="updateAddress"
      >
        <div class="row mb-6">
          <div class="col-2 m-auto">
            <label for="address1" class="form-label"> Reference </label>
          </div>
          <div class="col-10">
            <input
              class="form-control form-control-solid"
              :value="reference"
              disabled
            />
          </div>
        </div>

        <div class="row">
          <div class="col-2 m-auto">
            <label for="address1" class="form-label"> Prev Address </label>
          </div>
          <div class="col-9">
            <input
              class="form-control form-control-solid"
              :value="address.address1"
              disabled
            />
          </div>
          <div class="col-1">
            <a
              class="btn btn_address_map"
              target="_blank"
              :href="previousMapLink"
            >
              <i class="fas fa-map-marked-alt"></i>
            </a>
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-2 m-auto">
            <label for="address1" class="form-label"> Address </label>
          </div>
          <div class="col-9">
            <symbol-counter :current-number="addressLength" :min-length="3">
              <Field
                v-if="state.updatedAddress"
                id="address1"
                v-model="state.updatedAddress.address1"
                type="text"
                class="form-control form-control-solid"
                name="address1"
                placeholder="Address 1"
                required
                autocomplete="nope"
              >
              </Field>
            </symbol-counter>
          </div>
          <div class="col-1">
            <a class="btn btn_address_map" target="_blank" :href="mapLink">
              <i class="fas fa-map-marked-alt"></i>
            </a>
          </div>
        </div>

        <div class="row mb-6">
          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <ErrorMessage name="address1" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-2 m-auto">
            <label for="address1" class="form-label"> Prev Address 2 </label>
          </div>
          <div class="col-10">
            <input
              class="form-control form-control-solid"
              :value="address.address2"
              disabled
            />
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-2 m-auto">
            <label for="address2" class="form-label"> Address 2 </label>
          </div>
          <div class="col-10">
            <symbol-counter :current-number="address2Length" :min-length="3">
              <Field
                id="address2"
                v-model="state.updatedAddress.address2"
                type="text"
                class="form-control form-control-solid"
                name="address2"
                placeholder="Address 2"
                autocomplete="nope"
              />
            </symbol-counter>
          </div>
        </div>
        <div class="row mb-6">
          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <ErrorMessage name="address2" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <div class="row">
              <div class="col-4 m-auto">
                <label for="zip" class="form-label"> Prev Zip </label>
              </div>
              <div class="col-8">
                <input
                  class="form-control form-control-solid"
                  :value="address.zip"
                  disabled
                />
              </div>
            </div>
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-2 m-auto">
                <label for="city" class="form-label"> Prev City </label>
              </div>
              <div class="col-9">
                <input
                  class="form-control form-control-solid"
                  :value="address.city"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-6">
          <div class="col-4">
            <div class="row">
              <div class="col-4 m-auto">
                <label for="zip" class="form-label"> Zip </label>
              </div>
              <div class="col-8">
                <symbol-counter :current-number="zipLength" :min-length="3">
                  <Field
                    id="zip"
                    v-model="state.updatedAddress.zip"
                    type="text"
                    class="form-control form-control-solid"
                    name="zip"
                    placeholder="Zip"
                    required
                    autocomplete="nope"
                  />
                </symbol-counter>
              </div>
            </div>
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-2 m-auto">
                <label for="city" class="form-label"> City </label>
              </div>
              <div class="col-9">
                <symbol-counter :current-number="cityLength" :min-length="3">
                  <Field
                    id="city"
                    v-model="state.updatedAddress.city"
                    type="text"
                    class="form-control form-control-solid"
                    name="city"
                    placeholder="City"
                    required
                    autocomplete="nope"
                  />
                </symbol-counter>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="zip" />
              </div>
            </div>
          </div>
          <div class="col-7">
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="city" />
              </div>
            </div>
          </div>
        </div>

        <div v-if="state.countries" id="country_container">
          <div class="row mb-1">
            <div class="col-3 m-auto">
              <label for="country_id" class="form-label"> Country </label>
            </div>
            <div class="col-9">
              <Multiselect
                id="country_id"
                v-model="state.selectedCountry"
                :options="state.countries"
                :searchable="true"
                name="country_id"
                @change="countryChanged"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="country_id"></ErrorMessage>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="state.states" id="state_container">
          <div class="row mb-4">
            <div class="col-3 m-auto">
              <label for="state_id" class="form-label"> State </label>
            </div>
            <div class="col-9">
              <div class="col">
                <Multiselect
                  id="state_id"
                  v-model="state.selectedState"
                  name="state_id"
                  :options="state.states"
                  :searchable="true"
                  :disabled="state.states == null"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="state"></ErrorMessage>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <form-submit-button :loading="state.submitButtonLoading">
            Submit & Verify
          </form-submit-button>
        </div>
      </form>
    </template>
  </modal-component>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
} from "vue";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import { ErrorMessage, Field, useForm } from "vee-validate";
import Notify from "@/modules/common/helpers/Notify";
import ApiService from "@/core/services/ApiService";
import { ElMessage } from "element-plus/es";
import { MultiselectOptions } from "@/components/multiselect-options/MultiselectOptionsInterface";
import { AddressInterface } from "@/modules/addresses/interfaces/Address.interface";
import { setOptionsValues } from "@/components/multiselect-options/Options";
import Multiselect from "@vueform/multiselect";
import SymbolCounter from "@/modules/pathfinder/widgets/SymbolCounter.vue";

interface ComponentData {
  submitButtonLoading: boolean;
  countries: MultiselectOptions[] | null;
  states: MultiselectOptions[] | null;
  selectedCountry: number | null;
  selectedState?: number | null;
  updatedAddress?: AddressInterface;
}

export default defineComponent({
  name: "PathfinderAddressFixModal",
  components: {
    SymbolCounter,
    ModalComponent,
    FormSubmitButton,
    Field,
    ErrorMessage,
    Multiselect,
  },
  props: {
    reference: {
      type: String,
      required: true,
    },
    address: {
      type: Object as PropType<AddressInterface>,
      required: true,
    },
    messages: {
      type: Array,
      required: true,
    },
  },

  emits: ["updated", "hide"],

  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      submitButtonLoading: false,
      countries: null,
      states: null,
      updatedAddress: undefined,
      selectedCountry: null,
      selectedState: null,
    });
    const modal = ref<typeof ModalComponent | null>(null);

    const { handleSubmit, setFieldError } = useForm();

    const updateAddress = handleSubmit((values) => {
      const requestBody = {
        ...values,
        country_id: state.selectedCountry,
        state_id: state.selectedState,
        order_reference: props.reference,
      };

      ApiService.put(
        "pathfinder/address-update/" + props.address.id.toString(),
        requestBody
      )
        .then((data) => {
          state.submitButtonLoading = false;
          ElMessage.success("Address updated.");
          modal.value?.hide();
          emit("updated");
        })
        .catch((error) => {
          Notify.apiError(error, setFieldError);

          if (error.response.data.message) {
            Notify.apiError(error.response.data);
          }
        })
        .finally(() => {
          state.submitButtonLoading = false;
        });
    });

    const loadData = (countryId: number) =>
      new Promise((resolve, reject) => {
        ApiService.get(`address/countries`)
          .then(({ data }) => {
            state.countries = setOptionsValues(data.countries);

            ApiService.get(`address/countries/` + countryId + `/states`)
              .then(({ data }) => {
                if (data.states.length > 0) {
                  state.states = setOptionsValues(data.states);
                } else {
                  state.states = null;
                  state.selectedState = null;
                }
                return resolve(true);
              })
              .catch(reject);
          })
          .catch(reject);
      });

    const loadStates = (countryId: number) =>
      new Promise((resolve, reject) => {
        ApiService.get(`address/countries/` + countryId + `/states`)
          .then(({ data }) => {
            if (data.states.length > 0) {
              state.states = setOptionsValues(data.states);
            } else {
              state.states = null;
              state.selectedState = null;
            }
            return resolve(true);
          })
          .catch(reject);
      });

    const countryChanged = (value) => {
      if (value != null) {
        loadStates(value as number).then(() => {
          if (state.states != null) {
            state.selectedState = state.states[0].value;
          }
        });
      }
    };

    onMounted(() => {
      loadData(props.address.country.id).then(() => {
        state.updatedAddress = JSON.parse(JSON.stringify(props.address));

        state.selectedCountry = props.address.country.id;
        state.selectedState = props.address.state.id;
      });
    });

    const addressLength = computed(() => {
      return state.updatedAddress?.address1.length;
    });

    const address2Length = computed(() => {
      return state.updatedAddress?.address2?.length;
    });

    const zipLength = computed(() => {
      return state.updatedAddress?.zip?.length;
    });

    const cityLength = computed(() => {
      return state.updatedAddress?.city?.length;
    });

    const getMapLink = (
      address: AddressInterface,
      countryId: number | null,
      stateId?: number | null
    ): string => {
      let link = [
        "https://maps.google.com/?q=",
        address?.address1.toString() + ", " ?? "",
        address?.address2?.toString() + ", " ?? "",
        address?.zip.toString() + ", " ?? "",
        address?.city.toString() + ", " ?? "",
      ];

      if (state.countries != null) {
        link[link.length] =
          state.countries
            ?.filter((item) => item.value === countryId)[0]
            .label.toString() + ", ";
      }

      if (
        state.states != null &&
        stateId != null &&
        state.states[stateId - 1]
      ) {
        link[link.length] = state.states[stateId - 1].label.toString();
      }

      return link.join("");
    };

    const mapLink = computed(() => {
      if (state?.updatedAddress) {
        return getMapLink(
          state.updatedAddress,
          state?.selectedCountry,
          state?.selectedState
        );
      }

      return "";
    });

    const previousMapLink = computed(() => {
      return getMapLink(
        props.address,
        props.address.country.id,
        props.address.state?.id
      );
    });

    return {
      state,
      countryChanged,
      updateAddress,
      modal,
      addressLength,
      address2Length,
      zipLength,
      cityLength,
      mapLink,
      previousMapLink,
    };
  },
});
</script>

<style scoped>
label {
  margin-bottom: 0;
}
.form-control:disabled {
  background: none;
  border: none;
}

.btn_address_map i {
  font-size: 1.4rem;
}
</style>
