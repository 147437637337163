
import ResourcePage from "@/modules/resources/views/ResourcePage.vue";
import { defineComponent, reactive, ref } from "vue";
import ResourceTable from "@/modules/resources/components/ResourceTable/ResourceTable.vue";
import { setPageTitle } from "@/core/helpers/dom";
import { AddressInterface } from "@/modules/addresses/interfaces/Address.interface";
import PathfinderAddressFixModal from "@/modules/pathfinder/widgets/PathfinderAddressFixModal.vue";
import PathfinderNameFixModal from "@/modules/pathfinder/widgets/PathfinderNameFixModal.vue";

interface ComponentData {
  isSuccessfullyResynced: boolean;
  isEditAddress: boolean;
  isEditNameAddress: boolean;
  editableAddress?: AddressInterface;
  editableAddressMessages: string[];
  editableOrderReference?: string;
  isUpdateRequestSent: boolean;
}

export default defineComponent({
  name: "PathfinderResourcePage",
  components: {
    PathfinderAddressFixModal,
    ResourcePage,
    ResourceTable,
    PathfinderNameFixModal,
  },
  setup: function () {
    const state = reactive<ComponentData>({
      isSuccessfullyResynced: false,
      isEditAddress: false,
      isEditNameAddress: false,
      editableAddress: undefined,
      editableAddressMessages: [],
      editableOrderReference: undefined,
      isUpdateRequestSent: false,
    });

    const addressesTable = ref<typeof ResourceTable | null>(null);
    const addressesNamesTable = ref<typeof ResourceTable | null>(null);

    setPageTitle("Pathfinder");

    const addressUpdated = (): void => {
      state.isUpdateRequestSent = true;

      addressesTable.value?.loadData(false).then(() => {
        state.isUpdateRequestSent = false;
      });
    };

    const addressNamesUpdated = (): void => {
      state.isUpdateRequestSent = true;

      addressesNamesTable.value?.loadData(false).then(() => {
        state.isUpdateRequestSent = false;
      });
    };

    const editAddress = (
      address: AddressInterface,
      messages: string[],
      reference: string,
      isNameAddress = false
    ): void => {
      state.editableAddress = address;
      state.editableAddressMessages = messages;
      state.editableOrderReference = reference;

      isNameAddress
        ? (state.isEditNameAddress = true)
        : (state.isEditAddress = true);
    };

    return {
      state,
      editAddress,
      addressUpdated,
      addressNamesUpdated,
      addressesTable,
      addressesNamesTable,
    };
  },
});
