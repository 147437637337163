
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "SymbolCounter",
  props: {
    minLength: {
      type: Number as PropType<number>,
      required: false,
      default: 0,
    },
    currentNumber: {
      type: Number as PropType<number>,
      required: true,
    },
  },
});
