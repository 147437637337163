<template>
  <resource-page title="Pathfinder - Addresses" resource="pathfinder">
    <template #default="{ grid }">
      <resource-table
        v-if="grid != null"
        ref="addressesTable"
        :resource="'pathfinder'"
        :grid="grid"
      >
        {{ items }}
        <template #column-messages="{ row }">
          <div v-for="message in row.messages" :key="message.id">
            <strong class="text-danger">{{ message }}</strong>
          </div>
        </template>

        <template #column-delivery_address="{ row }">
          <div class="address_cell">
            <p>{{ row.delivery_address.address1 }}</p>
            <p>{{ row.delivery_address.address2 }}</p>
            <p>{{ row.delivery_address.city }}</p>
            <p>
              <span v-if="row.delivery_address.state.name">{{
                row.delivery_address.state.name + ", "
              }}</span>
              {{ row.delivery_address.country.name }}
            </p>
            <p>{{ row.delivery_address.zip }}</p>
          </div>
        </template>

        <template #actions="{ row }">
          <div
            v-if="
              state.editableAddress === row.delivery_address &&
              state.isUpdateRequestSent === true
            "
          >
            <div class="overlay-layer rounded">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
          <div v-else>
            <button
              type="button"
              class="btn btn-sm btn btn-primary"
              title="Update delivery address credentials"
              @click="
                editAddress(row.delivery_address, row.messages, row.reference)
              "
            >
              <i class="fas fa-pen"></i> Edit
            </button>
          </div>
        </template>
      </resource-table>
    </template>
  </resource-page>

  <resource-page
    title="Pathfinder - Names"
    resource="pathfinderNames"
    class="mt-3"
  >
    <template #default="{ grid }">
      <resource-table
        v-if="grid != null"
        ref="addressesNamesTable"
        :resource="'pathfinderNames'"
        :grid="grid"
      >
        <template #column-messages="{ row }">
          <div v-for="message in row.messages" :key="message.id">
            <strong class="text-danger">{{ message }}</strong>
          </div>
        </template>

        <template #actions="{ row }">
          <div
            v-if="
              state.editableAddress === row.delivery_address &&
              state.isUpdateRequestSent === true
            "
          >
            <div class="overlay-layer rounded">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
          <div v-else>
            <button
              type="button"
              class="btn btn-sm btn btn-primary"
              title="Update delivery address credentials"
              @click="
                editAddress(
                  row.delivery_address,
                  row.messages,
                  row.reference,
                  true
                )
              "
            >
              <i class="fas fa-pen"></i> Edit
            </button>
          </div>
        </template>
      </resource-table>
    </template>
  </resource-page>

  <PathfinderAddressFixModal
    v-if="state.isEditAddress === true && state.editableAddress !== undefined"
    :address="state.editableAddress"
    :messages="state.editableAddressMessages"
    :reference="state.editableOrderReference"
    @hide="state.isEditAddress = false"
    @updated="addressUpdated()"
  />

  <PathfinderNameFixModal
    v-if="
      state.isEditNameAddress === true && state.editableAddress !== undefined
    "
    :address="state.editableAddress"
    :messages="state.editableAddressMessages"
    :reference="state.editableOrderReference"
    @hide="state.isEditNameAddress = false"
    @updated="addressNamesUpdated()"
  />
</template>

<script lang="ts">
import ResourcePage from "@/modules/resources/views/ResourcePage.vue";
import { defineComponent, reactive, ref } from "vue";
import ResourceTable from "@/modules/resources/components/ResourceTable/ResourceTable.vue";
import { setPageTitle } from "@/core/helpers/dom";
import { AddressInterface } from "@/modules/addresses/interfaces/Address.interface";
import PathfinderAddressFixModal from "@/modules/pathfinder/widgets/PathfinderAddressFixModal.vue";
import PathfinderNameFixModal from "@/modules/pathfinder/widgets/PathfinderNameFixModal.vue";

interface ComponentData {
  isSuccessfullyResynced: boolean;
  isEditAddress: boolean;
  isEditNameAddress: boolean;
  editableAddress?: AddressInterface;
  editableAddressMessages: string[];
  editableOrderReference?: string;
  isUpdateRequestSent: boolean;
}

export default defineComponent({
  name: "PathfinderResourcePage",
  components: {
    PathfinderAddressFixModal,
    ResourcePage,
    ResourceTable,
    PathfinderNameFixModal,
  },
  setup: function () {
    const state = reactive<ComponentData>({
      isSuccessfullyResynced: false,
      isEditAddress: false,
      isEditNameAddress: false,
      editableAddress: undefined,
      editableAddressMessages: [],
      editableOrderReference: undefined,
      isUpdateRequestSent: false,
    });

    const addressesTable = ref<typeof ResourceTable | null>(null);
    const addressesNamesTable = ref<typeof ResourceTable | null>(null);

    setPageTitle("Pathfinder");

    const addressUpdated = (): void => {
      state.isUpdateRequestSent = true;

      addressesTable.value?.loadData(false).then(() => {
        state.isUpdateRequestSent = false;
      });
    };

    const addressNamesUpdated = (): void => {
      state.isUpdateRequestSent = true;

      addressesNamesTable.value?.loadData(false).then(() => {
        state.isUpdateRequestSent = false;
      });
    };

    const editAddress = (
      address: AddressInterface,
      messages: string[],
      reference: string,
      isNameAddress = false
    ): void => {
      state.editableAddress = address;
      state.editableAddressMessages = messages;
      state.editableOrderReference = reference;

      isNameAddress
        ? (state.isEditNameAddress = true)
        : (state.isEditAddress = true);
    };

    return {
      state,
      editAddress,
      addressUpdated,
      addressNamesUpdated,
      addressesTable,
      addressesNamesTable,
    };
  },
});
</script>

<style scoped>
.address_cell p {
  margin-bottom: 0.1rem;
}
</style>
