
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
} from "vue";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import { ErrorMessage, Field, useForm } from "vee-validate";
import Notify from "@/modules/common/helpers/Notify";
import ApiService from "@/core/services/ApiService";
import { ElMessage } from "element-plus/es";
import { MultiselectOptions } from "@/components/multiselect-options/MultiselectOptionsInterface";
import { AddressInterface } from "@/modules/addresses/interfaces/Address.interface";
import { setOptionsValues } from "@/components/multiselect-options/Options";
import Multiselect from "@vueform/multiselect";
import SymbolCounter from "@/modules/pathfinder/widgets/SymbolCounter.vue";

interface ComponentData {
  submitButtonLoading: boolean;
  countries: MultiselectOptions[] | null;
  states: MultiselectOptions[] | null;
  selectedCountry: number | null;
  selectedState?: number | null;
  updatedAddress?: AddressInterface;
}

export default defineComponent({
  name: "PathfinderAddressFixModal",
  components: {
    SymbolCounter,
    ModalComponent,
    FormSubmitButton,
    Field,
    ErrorMessage,
    Multiselect,
  },
  props: {
    reference: {
      type: String,
      required: true,
    },
    address: {
      type: Object as PropType<AddressInterface>,
      required: true,
    },
    messages: {
      type: Array,
      required: true,
    },
  },

  emits: ["updated", "hide"],

  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      submitButtonLoading: false,
      countries: null,
      states: null,
      updatedAddress: undefined,
      selectedCountry: null,
      selectedState: null,
    });
    const modal = ref<typeof ModalComponent | null>(null);

    const { handleSubmit, setFieldError } = useForm();

    const updateAddress = handleSubmit((values) => {
      const requestBody = {
        ...values,
        country_id: state.selectedCountry,
        state_id: state.selectedState,
        order_reference: props.reference,
      };

      ApiService.put(
        "pathfinder/address-update/" + props.address.id.toString(),
        requestBody
      )
        .then((data) => {
          state.submitButtonLoading = false;
          ElMessage.success("Address updated.");
          modal.value?.hide();
          emit("updated");
        })
        .catch((error) => {
          Notify.apiError(error, setFieldError);

          if (error.response.data.message) {
            Notify.apiError(error.response.data);
          }
        })
        .finally(() => {
          state.submitButtonLoading = false;
        });
    });

    const loadData = (countryId: number) =>
      new Promise((resolve, reject) => {
        ApiService.get(`address/countries`)
          .then(({ data }) => {
            state.countries = setOptionsValues(data.countries);

            ApiService.get(`address/countries/` + countryId + `/states`)
              .then(({ data }) => {
                if (data.states.length > 0) {
                  state.states = setOptionsValues(data.states);
                } else {
                  state.states = null;
                  state.selectedState = null;
                }
                return resolve(true);
              })
              .catch(reject);
          })
          .catch(reject);
      });

    const loadStates = (countryId: number) =>
      new Promise((resolve, reject) => {
        ApiService.get(`address/countries/` + countryId + `/states`)
          .then(({ data }) => {
            if (data.states.length > 0) {
              state.states = setOptionsValues(data.states);
            } else {
              state.states = null;
              state.selectedState = null;
            }
            return resolve(true);
          })
          .catch(reject);
      });

    const countryChanged = (value) => {
      if (value != null) {
        loadStates(value as number).then(() => {
          if (state.states != null) {
            state.selectedState = state.states[0].value;
          }
        });
      }
    };

    onMounted(() => {
      loadData(props.address.country.id).then(() => {
        state.updatedAddress = JSON.parse(JSON.stringify(props.address));

        state.selectedCountry = props.address.country.id;
        state.selectedState = props.address.state.id;
      });
    });

    const addressLength = computed(() => {
      return state.updatedAddress?.address1.length;
    });

    const address2Length = computed(() => {
      return state.updatedAddress?.address2?.length;
    });

    const zipLength = computed(() => {
      return state.updatedAddress?.zip?.length;
    });

    const cityLength = computed(() => {
      return state.updatedAddress?.city?.length;
    });

    const getMapLink = (
      address: AddressInterface,
      countryId: number | null,
      stateId?: number | null
    ): string => {
      let link = [
        "https://maps.google.com/?q=",
        address?.address1.toString() + ", " ?? "",
        address?.address2?.toString() + ", " ?? "",
        address?.zip.toString() + ", " ?? "",
        address?.city.toString() + ", " ?? "",
      ];

      if (state.countries != null) {
        link[link.length] =
          state.countries
            ?.filter((item) => item.value === countryId)[0]
            .label.toString() + ", ";
      }

      if (
        state.states != null &&
        stateId != null &&
        state.states[stateId - 1]
      ) {
        link[link.length] = state.states[stateId - 1].label.toString();
      }

      return link.join("");
    };

    const mapLink = computed(() => {
      if (state?.updatedAddress) {
        return getMapLink(
          state.updatedAddress,
          state?.selectedCountry,
          state?.selectedState
        );
      }

      return "";
    });

    const previousMapLink = computed(() => {
      return getMapLink(
        props.address,
        props.address.country.id,
        props.address.state?.id
      );
    });

    return {
      state,
      countryChanged,
      updateAddress,
      modal,
      addressLength,
      address2Length,
      zipLength,
      cityLength,
      mapLink,
      previousMapLink,
    };
  },
});
